var $ = require('jquery');
global.jQuery = require("jquery");

$( document ).ready(function() {	
	$(".navbar-startseite #accordion a, .bg-flag a").click(function(e) {
		if ($(this).hasClass('link-slide')) {
			e.preventDefault();
		}
		var rawHref = $(this)[0].hash;

		console.log("Test");
		
		if (rawHref) {
			$('html, body').animate({
				scrollTop: $(rawHref).offset().top-75
			}, 1000);			
		}
	});
});
